import React, { useState } from 'react';
import { Link } from 'gatsby';
import Input from '../input';
import Button from '../button';

const AccountLogin = ({
  login,
  loginErrorMessage,
  partnerCode,
  isLoading = false,
  showCouponInput = false,
  setCoupon,
  coupon,
  couponErrorMessage,
}) => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  return (
    <div className="container">
      <div
        style={{
          marginLeft: 75,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <img
          style={{ height: 41, width: 41 }}
          src="/images/herehear_logo.png"
        ></img>
        <p style={{ fontSize: 28, fontWeight: 500, paddingLeft: 10 }}>
          使用序號登入
        </p>
      </div>
      {showCouponInput ? (
        <>
          <div style={{ marginLeft: 75 }}>步驟一: 請輸入優惠序號</div>
          <div className="input-panel">
            {/* <p className="title message">請輸入您序號</p> */}
            <Input
              value={coupon}
              onChange={event => {
                setCoupon(event.target.value);
              }}
              placeholder="請輸入您的序號"
              type="text"
              errorMessage={couponErrorMessage}
            />
            {/* <hr style={{ marginTop: 10 }}/> */}
          </div>
          <div style={{ marginLeft: 75 }}>步驟二: 請用一下任一種方式登入</div>
        </>
      ) : null}

      <div style={{ position: 'relative' }}>
        <p
          style={{
            position: 'absolute',
            top: 29,
            left: 50,
            fontWeight: 'bold',
          }}
        >
          1.
        </p>
        <p
          style={{
            position: 'absolute',
            top: 83,
            left: 50,
            fontWeight: 'bold',
          }}
        >
          2.
        </p>
      </div>
      <div id="firebaseui-auth-container"></div>
      <hr />
      <div className="input-panel">
        <div className="message-container">
          <div className="title message">
            <span style={{ fontWeight: 'bold' }}>3.{'   '} </span>使用 Email
            登入，請輸入您的帳號密碼。
          </div>
        </div>
        <Input
          value={loginEmail}
          onChange={event => {
            setLoginEmail(event.target.value);
          }}
          placeholder="example@gmail.com"
          type="text"
        />
        <Input
          value={loginPassword}
          onChange={event => {
            setLoginPassword(event.target.value);
          }}
          placeholder="6 - 18 位數密碼"
          type="password"
          tips="請區分大小寫"
          errorMessage={loginErrorMessage}
        />
        <div className="btn-container">
          <Button
            text="確定"
            isLoading={isLoading}
            onClick={() => {
              login({
                email: loginEmail,
                password: loginPassword,
              });
            }}
          />
        </div>
        <span className="signup-message">
          還未有帳號?
          <Link to="/account/signup" state={{ partnerCode }}>
            <span>申請帳號</span>
          </Link>
        </span>
        <span className="forgot-message">
          <Link to="/account/forgot" state={{ partnerCode }}>
            <span>忘記密碼?</span>
          </Link>
        </span>
      </div>
      <style jsx>{`
        .container,
        .input-panel {
          width: 100%;
          min-width: 300px;
        }
        @media (min-width: 576px) {
          .container,
          .input-panel {
            width: 414px;
          }
        }
        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) {
        }

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
        }

        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
        }

        .message-container {
          margin-right: 60px;
        }
        .container {
          padding-top: 76px;
        }
        .input-panel {
          border-radius: 15px;
          top: -15px;
          padding-top: 26px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .message {
          max-width: 375px;
          font-size: 16px;
          text-align: left;
          color: #363636;
          line-height: 1.5;
          padding-bottom: 20px;
          padding-left: 51px;
        }
        .btn-container {
          margin-bottom: 20px;
        }
        .signup-message {
          font-size: 14px;
          color: #666565;
        }
        .signup-message span {
          font-weight: 500;
          color: #6924d6;
        }
        .forgot-message {
          font-size: 14px;
          padding-top: 20px;
        }

        .signup-message span {
          font-weight: 500;
          color: #6924d6;
        }
        hr {
          width: 70%;
          margin-top: 40px;
        }
      `}</style>
    </div>
  );
};

export default AccountLogin;
