/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import SEO from '../../../components/seo';
import Layout from '../../../components/layout';
import AccountLogin from '../../../components/accountIuput/accountLogin';
import { getDeviceToken, getUrlParameter } from '../../../util/util';

const url = process.env.GATSBY_API_URL;
let ui = null;

const Login = ({ location, showCouponInput = false }) => {
  const partnerCode = getUrlParameter(location, 'pcode');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const [coupon, setCoupon] = useState('');

  const login = async ({ email, password }) => {
    setLoginErrorMessage('');
    setCouponErrorMessage('');
    if (showCouponInput && !coupon) {
      setCouponErrorMessage('請輸入序號');
      return;
    }
    setIsLoading(true);
    await fetch(`${url}/api/v2/user/login`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        deviceToken: getDeviceToken(),
        partnerCode,
      }),
    })
      .then(async result => {
        setIsLoading(false);
        const jsonResult = await result.json();
        if (result.ok) {
          localStorage.setItem('accessToken', `${jsonResult.data.accessToken}`);
          if (partnerCode) {
            navigate('/account/coupon', {
              state: {
                platform: 0,
                partnerCode,
              },
            });
          } else if (showCouponInput) {
            useCoupon();
          } else {
            window.location.href = 'https://herehear.com.tw';
          }
          // setUser(jsonResult.data);
          // Router.push(`/coupon/${couponCode}/input`);
        } else {
          const { errorCode } = jsonResult;
          if (errorCode === 300301) {
            setLoginErrorMessage('帳號密碼錯誤');
          } else if (errorCode === 300302) {
            setLoginErrorMessage('Email 未認證');
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        // console.log('Log: error', error);
      });
  };

  const resetFirebaseLogin = () => {
    const uiConfig = {
      signInOptions: [
        // List of OAuth providers supported.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // 'apple.com',
      ],
      signInFlow: 'popup',
      // signInSuccessUrl: 'https://herehear.com.tw',
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          firebaseSignUp({
            provider: authResult.additionalUserInfo.providerId,
            firebaseToken: authResult.user.xa,
            showCouponInput,
          });
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          // 當是 true 時會觸發 signInSuccessUrl
          return false;
        },
      },
    };
    firebase.auth().signOut();
    ui.reset();
    ui.start('#firebaseui-auth-container', uiConfig);
  };

  const useCoupon = async () => {
    const storageCoupon = localStorage.getItem('herehear-coupon');
    if (!storageCoupon) {
      console.log('Log: useCoupon -> check');
      setCouponErrorMessage('請輸入序號');
      resetFirebaseLogin();
      return;
    }
    if (storageCoupon.length < 10) {
      setCouponErrorMessage('代碼長度錯誤，請確認優惠券代碼');
      resetFirebaseLogin();
      return;
    }
    await fetch(`${url}/api/v2/coupon`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({
        code: storageCoupon,
      }),
    })
      .then(async result => {
        const jsonResult = await result.json();
        if (result.ok) {
          navigate('/account/login/coupon/success', {
            state: {
              platform: 0,
              partnerCode,
            },
          });
        } else {
          const { errorCode } = jsonResult;
          if (errorCode === 300501) {
            setCouponErrorMessage('代碼輸入錯誤，請確認優惠券代碼');
          } else if (errorCode === 300504) {
            setCouponErrorMessage('優惠券已失效');
          } else if (errorCode === 300505) {
            setCouponErrorMessage('優惠券已使用');
          } else if (errorCode === 300503) {
            setCouponErrorMessage('優惠券已過期');
          } else if (errorCode === 300502) {
            setCouponErrorMessage('發生未知錯誤');
          }
          resetFirebaseLogin();
        }
      })
      .catch(error => {
        console.log('Log: -----------------');
        console.log('Log: error', error);
        console.log('Log: -----------------');
      });
  };

  const firebaseSignUp = async ({
    provider,
    firebaseToken,
    showCouponInput,
  }) => {
    setCouponErrorMessage('');
    let loginType = 'google';
    if (provider.indexOf('google') !== -1) loginType = 'google';
    if (provider.indexOf('facebook') !== -1) loginType = 'facebook';
    await fetch(`${url}/api/v3/auth/firebase/${loginType}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firebaseToken,
        devicePlatform: 'android',
        partnerCode,
        deviceToken: getDeviceToken(),
      }),
    })
      .then(async result => {
        setIsLoading(false);
        const jsonResult = await result.json();
        if (result.ok) {
          localStorage.setItem('accessToken', `${jsonResult.data.accessToken}`);
          if (partnerCode) {
            navigate('/account/coupon', {
              state: {
                platform: 0,
                partnerCode,
              },
            });
          } else if (showCouponInput) {
            useCoupon();
          } else {
            window.location.href = 'https://herehear.com.tw';
          }
          // setUser(jsonResult.data);
          // Router.push(`/coupon/${couponCode}/input`);
        } else {
          setCouponErrorMessage('登入失敗請稍後再試');
          resetFirebaseLogin();
        }
      })
      .catch(error => {
        // setIsLoading(false);
        resetFirebaseLogin();
        console.log('Log: error', error);
      });
  };

  const handleOnLoad = () => {
    try {
      let firebaseConfig = {
        apiKey: 'AIzaSyBH9sbI13tCp0BOAnA2hemqWpcxTAYb4UY',
        authDomain: 'herehearv2-1c8b3.firebaseapp.com',
        databaseURL: 'https://herehearv2-1c8b3.firebaseio.com',
        projectId: 'herehearv2-1c8b3',
        storageBucket: 'herehearv2-1c8b3.appspot.com',
        messagingSenderId: '387158937525',
        appId: '1:387158937525:web:17d5d12846f32e142593fc',
        measurementId: 'G-9XVF8L6SJ1',
      };
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      ui = new firebaseui.auth.AuthUI(firebase.auth());
      const uiConfig = {
        signInOptions: [
          // List of OAuth providers supported.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          // 'apple.com',
        ],
        // signInFlow: 'popup',
        // signInSuccessUrl: 'https://herehear.com.tw',
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            firebaseSignUp({
              provider: authResult.additionalUserInfo.providerId,
              firebaseToken: authResult.user.xa,
              showCouponInput,
            });
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            // 當是 true 時會觸發 signInSuccessUrl
            return false;
          },
        },
      };
      ui.start('#firebaseui-auth-container', uiConfig);
    } catch (error) {
      console.log('Log: ---------------------------------');
      console.log('Log: handleOnLoad -> error', error);
      console.log('Log: ---------------------------------');
    }
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  return (
    <Layout
      showHamburgerBtn={false}
      showAccountBtn={false}
      showHeader={true}
      contentStyle={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url("/images/background.jpg")`,
      }}
    >
      <SEO
        title="登入"
        scripts={[
          <link
            type="text/css"
            rel="stylesheet"
            href="https://www.gstatic.com/firebasejs/ui/4.5.0/firebase-ui-auth.css"
          />,
          <script src="https://www.gstatic.com/firebasejs/7.14.2/firebase-app.js"></script>,
          <script src="https://www.gstatic.com/firebasejs/ui/4.5.0/firebase-ui-auth__zh_tw.js"></script>,
          <script src="https://www.gstatic.com/firebasejs/7.14.2/firebase-auth.js"></script>,
          <script src="https://www.gstatic.com/firebasejs/7.14.2/firebase-firestore.js"></script>,
        ]}
        handleOnLoad={handleOnLoad}
      />
      <div>
        <AccountLogin
          login={login}
          loginErrorMessage={loginErrorMessage}
          partnerCode={partnerCode}
          isLoading={isLoading}
          showCouponInput={showCouponInput}
          coupon={coupon}
          setCoupon={value => {
            setCoupon(value);
            localStorage.setItem('herehear-coupon', value);
          }}
          couponErrorMessage={couponErrorMessage}
        />
        <style jsx>{`
          div {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </div>
    </Layout>
  );
};

export default Login;
