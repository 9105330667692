import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

const Button = props => (
  <>
    <button style={props.style} className="btn" {...props}>
      {props.isLoading === true ? (
        <PulseLoader loading={true} color={'#fff'} size={12} />
      ) : (
        props.text
      )}
      {/* {props.text}
      <SyncLoader loading={true} color={'#fff'}/> */}
    </button>
    <style jsx>{`
      .btn {
        border: none;
        background-color: #6924d6;
        height: 40px;
        border-radius: 20px;
        width: 170px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }
      .btn:hover {
        background-color: #5e20bf;
      }
      .btn:active {
        background-color: #4d18a1;
      }
    `}</style>
  </>
);

export default Button;
